.home {
	background: url('../../images/home.jpg');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: auto 400px;
	background-color: #c7deff;
	padding:35px 20px;

  .col-sm-6:nth-child(1) {
    color:#1b1b1b;
  }

}
.home form {
  background: #fff;
  box-shadow: 4px 4px 12px #a3b4ce;
  padding: 18px 24px;

  textarea {
  	max-width:100%;
  	max-height:460px;
  }

	.checkbox p {
		margin:11px 0;
	}

	.form-group label {
		margin: 7px 0;
	}
	.form-group .col-xs-4,
	.form-group .col-sm-4, {
		text-align: right;
	}

	.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    font-size: 10px;
    margin-top: 3px;
	}

	.form-group .row  {
		margin-bottom:15px;
	}

}