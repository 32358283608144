// Variables
@import "variables";

// depend
@import "select";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Fonts

// Typography
h1 
{
	color: $brand-primary;
	font-weight:900;
}

.menu-bar {
	background: $brand-primary;
	margin: 0px;
	padding:10px;
	color:#fff;

	* {
		margin:0;
		padding:0;
		font-size:16px;
	}

}

// Sections
@import "header";
@import "sidebar";
@import "footer";

// Pages
@import "home";
@import "complaints";