header {
	margin-top:25px;
  margin-bottom:10px;
  .full {
    width:100%;
  }
}

.margintop {
  margin-top:15px;
}

.errorsPrompt {
  color:#a94442;
}