.toggleResponse > form {
  transition: height 0.35s ease;
  overflow:hidden;
  height:370px;
  float: left;
  width:100%;
}
.toggleResponse.disabled > form {
  height:0px;
}
.responded {
  background:#003464;
  color:#fff;
  .btn.btn-primary {
    background:#fff;
    color: #003464;
  }
  small {
    color:#fff;
  }
  form .btn {
    color:#111;
    background:#fff;
  }
}